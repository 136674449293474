// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";





function MainDashboard(props: any) {

    return (
        <div className="bg-gray-700 mx-0 w-full">
            <h1 className="text-5xl">Dashboard</h1>
        </div>
    );
}

export default MainDashboard;
