import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

// pages
import Home from "./Pages/home/Home";
import UploadXLSX from "./Pages/uploadXlsx/UploadXLSX";
import ProjectsDashboard from './Pages/Dashboard/ProjectsDashboard/ProjectsDashboard';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Dashboard from './Pages/Dashboard/Dashboard';

// components
import NavBar from "./Components/navbar/Navbar";
import TwoButtonsPopup from './Components/Popups/TwoButtonsPopup';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import ResetPassword from './Pages/Auth/ResetPassword';


type contextType = {
	token: string;
	setToken: React.Dispatch<React.SetStateAction<string>>,
	currentPopup: JSX.Element | null,
	setCurrentPopup: React.Dispatch<React.SetStateAction<JSX.Element | null>>
}
export const context = React.createContext<contextType | undefined>(undefined);

function App() {
	const [token, setToken] = useState(localStorage.getItem("token") || "");
	const [currentPopup, setCurrentPopup] = useState<JSX.Element | null>(null);



	// on page load
	useEffect(() => {
		document.title = "Nergietech";
		document.body.style.backgroundColor = "#1F2937";
	}, []);

	// when token changes
	useEffect(() => {
		localStorage.setItem("token", token);
		console.log("saved token to local storage : " + token)
	}, [token]);

	function isUserConnected() {
		return (token !== "" && token !== undefined);
	}

	return (
		<context.Provider value={{ token, setToken, currentPopup, setCurrentPopup }}>
			<BrowserRouter>
				<NavBar />

				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/upload" element={<UploadXLSX />} />
					<Route path="/login" element={
						(!isUserConnected()) ?
							<Login /> :
							<Navigate to="/" />
					} />
					<Route path="/signup" element={
						(!isUserConnected()) ?
							<Register /> :
							<Navigate to="/" />
					} />
					<Route path="/forgot-password" element={
						(!isUserConnected()) ?
							<ForgotPassword /> :
							<Navigate to="/" />
					} />
					<Route path="/reset-password" element={
						(!isUserConnected()) ?
							<ResetPassword /> :
							<Navigate to="/" />
					} />
					<Route path="/dashboard" element={
						(isUserConnected()) ?
							<Dashboard /> :
							<Navigate to="/" />
					} />
					<Route path="/dashboard/projects" element={
						(isUserConnected()) ?
							<Dashboard /> :
							<Navigate to="/login" />
					}
					/>

				</Routes>
				{currentPopup}
			</BrowserRouter>
		</context.Provider>
	);

}

export default App;
