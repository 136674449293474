// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import React from "react";



function CreateNewProject({onClick}: {onClick: any}) {
    

    return (

        <div className="p-4 bg-white shadow-lg border border-black rounded-2xl w-4/5 min-w-[250px] max-w-[400px] h-64 dark:bg-gray-400 flex flex-col hover:bg-white hover:border-gray-800 hover:border-4 select-none cursor-pointer justify-center" onClick={onClick}>

            <p className="mt-auto text-6xl font-bold text-gray-900">
                +
            </p>
            <p className="mb-auto text-4xl font-bold text-gray-900">
                Nouveau projet
            </p>


        </div>

    );
}

export default CreateNewProject;
