import React, { useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { backEndUrl } from '../../config';

export default function UploadXLSX() {
	const navigate = useNavigate();


	const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
	const [infoMessage, setInfoMessage] = useState('');


	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		setSelectedFile(file);
	};

	const handleSendFile = async () => {
		if (selectedFile) {
			const formData = new FormData();
			formData.append('excelFile', selectedFile);
			try {

				let url = `${backEndUrl}/uploadXLSX`;
				let options = {
					method: 'POST',
					body: formData,
				};


				const response = await fetch(url, options);
				const data = await response.json();
				if (response.ok) {
					// navigate('/');
					console.log('File uploaded successfully')
					setInfoMessage('File uploaded successfully');
				} else {
					setInfoMessage(data.message);
				}
			} catch (error: any) {
				setInfoMessage(error.toString());
			}
		}
	};



	return (
		<>
			<div className='flex w-full h-[calc(100vh-100px)]'>
				<div className='bg-gray-500 w-2/5 mx-auto my-auto min-h-[250px] rounded-lg border border-black'>
					<div className='text-4xl font-bold text-center'>
						<h1>Envoie de fichier Excel</h1>
					</div>
					<div className="flex flex-col">
						<label htmlFor="fileInput" className="bg-primarylink w-1/3 mx-auto border border-black text-center text-white rounded-md p-2 mt-2 font-bold cursor-pointer hover:border-white">
							<span>Choisir un fichier</span>
						</label>
						<input
							type="file"
							id="fileInput"
							className="hidden"
							onChange={handleFileChange}
						/>
						<div className='text-center'>
							{selectedFile && <p>Fichier Sélectionné: {selectedFile.name}</p>}
							{!selectedFile && <p>Aucun fichier sélectioné</p>}
						</div>
						<input
							type="button"
							className="bg-primarylink w-1/2 mx-auto border border-black text-center text-white rounded-md p-2 mt-2 font-bold cursor-pointer hover:border-white"
							disabled={!selectedFile}
							value="Téléverser le fichier"
							onClick={handleSendFile}
						/>
						<div className='text-center mt-5'>
							{infoMessage && <p className="error-message">{infoMessage}</p>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
