import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { context } from "../../App";
import { backEndUrl } from "../../config";

function Register() {
    const navigate = useNavigate();
    const myContext = useContext(context)!;

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    function handleFirstNameChange(event: any) {
        setFirstName(event.target.value);
    }

    function handleLastNameChange(event: any) {
        setLastName(event.target.value);
    }

    function handlePasswordChange(event: any) {
        setPassword(event.target.value);
    }

    function handlePasswordConfirmChange(event: any) {
        setPasswordConfirm(event.target.value);
    }

    async function handleLoginSubmit(event: any) {
        event.preventDefault();


        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": email,
                "password": password,
                "first_name": firstName,
                "last_name": lastName
            })
        };

        const response = await fetch(`${backEndUrl}/auth/register`, options)
        const data = await response.json()
        if (!response.ok) {
            console.error(response.statusText);
            if (response.status === 401) {
                alert(data.message)
            }
            else {
                alert(data.message)
            }
            return;
        }

        myContext.setToken(data.token);

        alert("Compte crée avec succès, vous allez être redirigé vers la page de connexion.")
        navigate("/dashboard/projects");
    }

    useEffect(() => {
        // navigate("/project");
    }, []);



    return (
        <div className="block mx-auto w-96 lg:w-2/6 min-h-full flex-col justify-center px-6 py-12 lg:px-8 shadow md:mt-10 bg-gray-100 rounded-lg border-gray-200 border">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Register</h2>
            </div>

            <div className="mt-10">
                <form className="grid grid-cols-1 gap-4 md:grid-cols-2" action="#" method="POST">
                    <div className="col-span-1 md:col-span-2">
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Email</label>
                        <input id="email" name="email" type="email" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter email" onChange={handleEmailChange} />
                    </div>

                    <div className="">
                        <label className="block text-lg font-semibold leading-6 text-gray-900">First Name</label>
                        <input id="firstName" name="firstName" type="text" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter first name" onChange={handleFirstNameChange} />

                    </div>

                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Last Name</label>
                        <input id="lastName" name="lastName" type="text" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter last name" onChange={handleLastNameChange} />
                    </div>

                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Password</label>
                        <input id="password" name="password" type="password" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter Password" onChange={handlePasswordChange} />
                    </div>

                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Confirm Password</label>
                        <input id="confirmPassword" name="confirmPassword" type="password" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter Password" onChange={handlePasswordConfirmChange} />
                    </div>

                    <div className="col-span-1 md:col-span-2 mt-5">
                        <button type="submit" className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={handleLoginSubmit}>Sign Up</button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Already a member?
                    <Link to={"/login"} className="font-semibold leading-6 text-green-600 hover:text-green-500"> Sign in</Link>
                </p>
            </div>
        </div>
    );
}

export default Register;
