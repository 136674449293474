// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { useContext, useState } from "react";
import { context } from "../../App";



interface Props {
    title: string;
    desc: string;
    defaultText: string;
    button1Text: string;
    button2Text: string;
    clickButton1: (text: string) => void;
    clickButton2: any;
    // any props that come into the component
}
function TextEditPopup({ title, desc, defaultText, button1Text, button2Text, clickButton1, clickButton2 }: Props) {
    const myContext = useContext(context)!;
    const [text, setText] = useState(defaultText);

    function handleChangeText(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setText(e.target.value);
    }

    function handleOnClickButton1() {
        clickButton1(text);
    }

    function handleOnClickButton2() {
        clickButton2();
    }

    function handleClickOutside() {
        myContext.setCurrentPopup(null);
    }


    return (
        <div className="fixed left-0 top-0 w-full h-full ">
            <div className="bg-black bg-opacity-50 w-full h-full" onClick={handleClickOutside}>

            </div>
            <div className="absolute w-96 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-primarybg left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                <div className="w-full h-full text-center">
                    <div className="flex flex-col justify-between h-full">
                        <p className="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                            {title}
                        </p>
                        <p className="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                            {desc}
                        </p>
                        <textarea className="w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent min-h-[10rem]" id="comment" placeholder="Accessory description" name="description" value={text} onChange={handleChangeText}>
                        </textarea>
                        <div className="flex items-center justify-between w-full gap-4 mt-8">
                            <button type="button" className="py-2 px-4  bg-primarylink hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-1  rounded-lg " onClick={handleOnClickButton1}>
                                {button1Text}
                            </button>
                            <button type="button" className="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-green-500 focus:ring-offset-green-200 text-green-900 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-1  rounded-lg " onClick={handleOnClickButton2}>
                                {button2Text}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default TextEditPopup;
