import { useDebouncedCallback } from "use-debounce";
import { context } from "../../../App";
import React, { useContext } from "react";
import { backEndUrl } from "../../../config";
import { accessoryType, actionType } from "./Types";

// handle edit project with debounce, so it doesn't spam the server, then update the calculations
export function useHandleFetchEditProject() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, column: string, value: string | number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                [column]: value
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id, options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update solar factor
            if (column === 'location') {
                dispatch({
                    type: "edit_project",
                    project_id: project_id,
                    column: "solar_factor",
                    value: resJSON.project.solar_factor
                });
            }
            // update calculated values
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}

export function useHandleFetchEditProjectAccessory() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, project_accessories_id: number, column: string, value: string | number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                [column]: value
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/accessories/' + project_accessories_id, options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        1000
    );

    return debounced;
}

export function useHandleFetchAddProjectAccessory() {
    const myContext = useContext(context)!;

    const func = async (project_id: number, accessory: accessoryType, dispatch: React.Dispatch<actionType>) => {
        const options = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                quantity: accessory.quantity,
                hours_day: accessory.hours_day,
                days_week: accessory.days_week
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/accessories/' + accessory.accessories_id, options)
        const resJSON = await response.json();



        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }
        if (response.status !== 200) {
            throw new Error(resJSON.message);
        }
        else {
            // add accessory
            dispatch({ type: "add_accessory", accessory: resJSON.new_project_accessory })

            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
        return resJSON;
    }
    return func;
}

export function useHandleFetchDeleteProjectAccessory() {

    const myContext = useContext(context)!;

    const func = async (project_id: number, accessory_id: number, dispatch: React.Dispatch<actionType>) => {
        const options = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/accessories/' + accessory_id, options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status !== 200) {
            throw new Error(resJSON.message);
        }
        else {
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
        return resJSON;

    }
    return func;
}

export function useHandleFetchEditSolarPanels() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, solarPanelId: number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                solar_panel_id: solarPanelId
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/solar_panels', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "edit_solar_panel",
                solar_panel: resJSON.project.solar_panels
            })
            
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}

export function useHandleFetchEditSolarPanelsData() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, solarPanelId: number, column: string, value: string | number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                solar_panel_id: solarPanelId,
                [column]: value
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/solar_panels', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}


export function useHandleFetchEditBattery() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, batteryId: number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                battery_id: batteryId,
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/batteries', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "edit_battery",
                battery: resJSON.project.batteries
            })
           
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}

export function useHandleFetchEditBatteryData() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, batteryId: number, column: string, value: string | number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                battery_id: batteryId,
                [column]: value
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/batteries', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}

export function useHandleFetchEditChargeController() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, chargeControllerId: number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                charge_controller_id: chargeControllerId,
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/charge_controllers', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "edit_charge_controller",
                charge_controller: resJSON.project.charge_controllers
            })
           
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}

export function useHandleFetchEditChargeControllerData() {
    const myContext = useContext(context)!;


    const debounced = useDebouncedCallback(async (project_id: number, chargeControllerId: number, column: string, value: string | number, dispatch: React.Dispatch<actionType>) => {
        // get all projects
        const options = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                charge_controller_id: chargeControllerId,
                [column]: value
            })
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id + '/charge_controllers', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        if (response.status === 200) {

            // update calculated values
            console.log(resJSON.project.calculated);
            dispatch({
                type: "refresh_calculations",
                calculations: resJSON.project.calculated
            });
        }
    },
        // delay in ms
        100
    );

    return debounced;
}