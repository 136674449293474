import { projectType, actionType } from "../Types";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function SolarFactorPanel({ project, dispatch }: Props) {
    return (
        <div className="container max-w-4/5 px-4 mx-auto sm:px-8">
            <div className="pb-8">
                <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                    <div className="inline-block min-w-full overflow rounded-lg shadow">
                        <table className="min-w-full leading-normal border table-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">

                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Jan
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Feb
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Mar
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Apr
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        May
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Jun
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold  text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Jul
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold  text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Aug
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Sep
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Oct
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Nov
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Dec
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    <SolarPanelRow category={"Horizontal"} data={project.solar_factor.SW_DWN} />
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className=" w-1/4 p-4 bg-gray-100 border shadow-lg rounded-2xl mx-auto my-5">
                        <p className="ml-2 text-gray-700 text-lg">
                            Facteur Solaire
                        </p>

                        <p className="my-4 text-4xl font-bold text-gray-800">
                            {project.solar_factor.SW_DWN ? project.solar_factor.SW_DWN["avg"] : 0} kWh/m<sup>2</sup>/jour
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SolarFactorPanel;





type solarPanelProp = {
    category: string,
    data: {
        [key: string]: number
    }
}

function SolarPanelRow({ category, data }: solarPanelProp) {



    return (
        <tr className="my-5">
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {category}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["jan"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["feb"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["mar"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["apr"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["may"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["jun"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["jul"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["aug"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["sep"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["oct"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-gray-200 border-b border-gray-200 "}>
                {data ? data["nov"] : 0}
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {data ? data["dec"] : 0}
            </td>

        </tr>
    )
}
