
import { accessoryType, projectType, actionType } from "../Types";
import TwoButtonsPopup from "../../../../Components/Popups/TwoButtonsPopup";
import { useContext, useState } from "react";
import { context } from "../../../../App";
import { useHandleFetchDeleteProjectAccessory, useHandleFetchEditProjectAccessory } from "../ApiCalls";
import TextEditPopup from "../../../../Components/Popups/TextEditPopup";

type Props = {
    project: projectType,
    accessory: accessoryType,
    dispatch: React.Dispatch<actionType>
}

function AccessoryRow({ project, accessory, dispatch }: Props) {


    const myContext = useContext(context)!;
    const [isEditing, setIsEditing] = useState(false);
    const handleFetchEditProjectAccessory = useHandleFetchEditProjectAccessory();
    const handleFetchDeleteProjectAccessory = useHandleFetchDeleteProjectAccessory();

    async function handleConfirmDelete() {
        try {
            await handleFetchDeleteProjectAccessory(project.project_id, accessory.project_accessories_id, dispatch);
            dispatch({ type: "delete_accessory", project_accessories_id: accessory.project_accessories_id });

        } catch (err) {
            console.error(err);
        }
        myContext.setCurrentPopup(null);
    }

    function handleDelete() {
        myContext.setCurrentPopup(
            <TwoButtonsPopup title="Delete Accessory" desc="Are you sure you want to delete this accessory?" button1Text="Delete" button2Text="Cancel" clickButton1={handleConfirmDelete} clickButton2={() => myContext.setCurrentPopup(null)} />
        )
    }

    function openNamePopup() {
        myContext.setCurrentPopup(
            <TextEditPopup title="Edit Accessory Name" desc="Edit name of accessory" defaultText={accessory.accessories_name} button1Text="Edit" button2Text="Cancel" clickButton1={handleChangeName} clickButton2={() => myContext.setCurrentPopup(null)} />
        )
    }
    function handleChangeName(newName: string) {
        myContext.setCurrentPopup(null);


        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "accessories_name", newName, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "accessories_name", value: newName });
    }

    function openDescriptionPopup() {
        myContext.setCurrentPopup(
            <TextEditPopup title="Edit Description" desc="Edit description of accessory" defaultText={accessory.accessories_description} button1Text="Edit" button2Text="Cancel" clickButton1={handleChangeDescription} clickButton2={() => myContext.setCurrentPopup(null)} />
        )
    }
    function handleChangeDescription(newDescription: string) {
        myContext.setCurrentPopup(null);


        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "accessories_description", newDescription, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "accessories_description", value: newDescription });
    }

    function handleChangeAmps(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9.]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "amps", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "amps", value: newValue });
    }
    function handleChangeVoltage(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9.]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "voltage", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "voltage", value: newValue });
    }
    function handleChangeWatt(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9.]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "watt", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "watt", value: newValue });
    }


    function handleChangeType(e: React.ChangeEvent<HTMLSelectElement>) {
        let newValue = e.target.value;


        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "acdc", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "acdc", value: newValue });
    }

    function handleChangeDaysWeek(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number
        const newNumber = parseInt(newValue);
        if (newNumber < 1 || newNumber > 7) return; // cancel anything thats not between 1 and 7

        // console.log(newValue)
        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "days_week", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "days_week", value: newValue });
    }

    function handleChangeHoursDay(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number
        const newNumber = parseInt(newValue);
        if (newNumber < 1 || newNumber > 24) return; // cancel anything thats not between 1 and 7

        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "hours_day", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "hours_day", value: newValue });
    }

    function handleChangeQuantity(e: React.ChangeEvent<HTMLInputElement>) {
        let newValue = e.target.value;

        if (!/^[0-9]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number
        const newNumber = parseInt(newValue);
        if (newNumber < 1 || newNumber > 100) return; // cancel anything thats not between 1 and 7

        handleFetchEditProjectAccessory(project.project_id, accessory.project_accessories_id, "quantity", newValue, dispatch)
        dispatch({ type: "edit_project_accessory", project_accessories_id: accessory.project_accessories_id, column: "quantity", value: newValue });
    }

    return (
        <tr className="my-5">
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                {/* <input type="text" className={"text-gray-900 whitespace-no-wrap text-center max-w-[75px] break-normal " + (isEditing && "border round")} onChange={handleChangeName} value={accessory.accessories_name} disabled={!isEditing} />
                 */}
                 <p className={"text-gray-900 text-center w-[150px] break-words " + (isEditing && "border round")} onClick={() => isEditing && openNamePopup()}>
                    {accessory.accessories_name}
                </p>
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <p className={"text-gray-900 text-center w-[150px] break-words " + (isEditing && "border round")} onClick={() => isEditing && openDescriptionPopup()}>
                    {accessory.accessories_description}
                </p>
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <input type="text" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeAmps} value={accessory.amps} disabled={!isEditing} />
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <input type="text" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeVoltage} value={accessory.voltage} disabled={!isEditing} />
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <input type="text" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeWatt} value={accessory.watt} disabled={!isEditing} />
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <select className={"block px-1 py-1 text-gray-700 bg-white border border-gray-300 shadow-sm w-12 " + (isEditing ? "border round" : "appearance-none")} onChange={handleChangeType} value={accessory.acdc} disabled={!isEditing} >
                    <option value="ac">AC</option>
                    <option value="dc">DC</option>
                </select>

            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <input type="number" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeQuantity} value={accessory.quantity} disabled={!isEditing} />
            </td>
            <td className={"px-5 py-5 text-sm bg-white border-b border-gray-200 "}>
                <input type="number" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeHoursDay} value={accessory.hours_day} disabled={!isEditing} />
            </td>
            <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                <input type="number" className={"text-gray-900 whitespace-no-wrap text-center max-w-[50px] " + (isEditing && "border round")} onChange={handleChangeDaysWeek} value={accessory.days_week} disabled={!isEditing} />
            </td>
            <td className="pl-5 py-5 text-sm bg-white border-b border-gray-200">
                {isEditing ?
                    <button className="text-green-700 hover:text-green-900" onClick={() => setIsEditing(false)}>
                        Exit
                    </button>
                    :
                    <button className="text-green-700 hover:text-green-900" onClick={() => setIsEditing(true)}>
                        Edit
                    </button>
                }


            </td>
            <td className="pr-5 py-5 text-sm bg-white border-b border-gray-200">
                {!isEditing &&
                    <button className="text-red-600 hover:text-red-900 ml-5" onClick={handleDelete}>
                        Delete
                    </button>
                }
            </td>
        </tr>
    )
}

export default AccessoryRow;