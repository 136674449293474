// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {

	const navigate = useNavigate();

	useEffect(() => {
		navigate("/dashboard/projects");
	}, []);


	return (
		<div className="App">
			<header className="App-header">
				<p>

				</p>
			</header>
		</div>
	);
}

export default Home;
