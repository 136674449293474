
import { useContext, useEffect, useState } from "react";
import { useHandleFetchEditSolarPanels, useHandleFetchEditSolarPanelsData } from "../ApiCalls";
import { projectType, solarPanelType, actionType } from "../Types";
import { backEndUrl } from "../../../../config";
import { context } from "../../../../App";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function PVPanel({ project, dispatch }: Props) {
    const handleFetchEditSolarPanel = useHandleFetchEditSolarPanels()
    const handleFetchEditSolarPanelData = useHandleFetchEditSolarPanelsData()
    const [pvPanels, setPvPanels] = useState<solarPanelType[]>([])

    const myContext = useContext(context)!;

    useEffect(() => {
        async function fetchData() {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myContext.token}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(backEndUrl + '/solarPanel', options)
            const resJSON = await response.json();
            setPvPanels(resJSON);



        }
        fetchData()
            .catch(err => console.error(err));
    }, [])


    function handleChangeSolarPanel(e: React.ChangeEvent<HTMLSelectElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        const newSolarPanel = pvPanels.find(pvPanel => pvPanel.solar_panel_id === parseInt(newValue));
        // update state of current value
        if (newSolarPanel) {
            // dispatch({ type: "edit_solar_panel", solar_panel: newSolarPanel })
            handleFetchEditSolarPanel(project.project_id, newSolarPanel.solar_panel_id, dispatch)

        }
    }


    // handle change panel data
    function handleChangeData(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        const column = e.target.name;

        if (!/^[0-9.-]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditSolarPanelData(project.project_id, project.solar_panels.solar_panel_id, column, newValue, dispatch)
        dispatch({ type: "edit_solar_panel_data", column: column, value: newValue });
    }


    return (
        <div className="grid grid-cols-4 gap-4 m-10">
            <select className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-64 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="solar Panels" onChange={handleChangeSolarPanel}>
                <option value={project.solar_panels ? project.solar_panels.solar_panel_id : ""} disabled>Select Solar Panel</option>
                {pvPanels.map((pvPanel, index) => {
                    return (
                        <option key={index} value={pvPanel.solar_panel_id}>
                            {pvPanel.product_name}
                        </option>
                    )
                })}

            </select>
            <div className="col-span-3 row-span-1 "></div>
            <div className="bg-yellow-100 col-span-2 row-span-4 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Solar Panel
                </div>
                <div className="text-center text-2xl font-bold">
                    {project.solar_panels ? project.solar_panels.product_name : "test"}
                </div>

                <div className="grid grid-cols-2 gap-2 justify-end">
                    <img className="row-span-6 object-contain max-h-[300px]" src={backEndUrl + "/" + project.solar_panels?.image_url}/>
                    <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                        Power :
                        <input name="nominal_power" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.nominal_power : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">W</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Vmp :
                        <input name="optimal_voltage" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.optimal_voltage : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">V</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Voc :
                        <input name="open_circuit_voltage" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.open_circuit_voltage : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">V</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Imp :
                        <input name="optimal_current" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.optimal_current : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">A</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Isc :
                        <input name="short_circuit_current" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.short_circuit_current : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">A</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Pmax Coefficient :
                        <input name="temperature_coefficient_pmax" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.solar_panels ? project.solar_panels.temperature_coefficient_pmax : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">%/K</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Width :
                        <input name="width" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.width : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">cm</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Voc Coefficient :
                        <input name="temperature_coefficient_voc" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.solar_panels ? project.solar_panels.temperature_coefficient_voc : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">%/K</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Height :
                        <input name="height" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.height : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">cm</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Nom Voltage :
                        <input name="nominal_voltage" type="number" className="bg-white border rounded-md px-2 max-w-[40px]" value={project.solar_panels ? project.solar_panels.nominal_voltage : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">V</p>
                    </div>

                    <div className="mx-2 px-2 py-1 row-span-3">
                        Files
                        <div>
                            {project.solar_panels && project.solar_panels.documents ? project.solar_panels.documents.map((file, index) => {
                                return (
                                    <div key={index} className="mx-2 bg-white border rounded-md px-2">
                                        <a href={backEndUrl + "/" + file.document_url} target="_blank" rel="noreferrer">{file.document_name}</a>
                                    </div>
                                )
                            }
                            ) : ""}


                        </div>

                    </div>

                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Max. Fuse :
                        <input name="max_fuse_rating" type="number" className="bg-white border rounded-md px-2 max-w-[40px]" value={project.solar_panels ? project.solar_panels.max_fuse_rating : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">A</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Max. Voltage :
                        <input name="max_voltage" type="number" className="bg-white border rounded-md px-2 max-w-[55px]" value={project.solar_panels ? project.solar_panels.max_voltage : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">V</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Price:
                        <div className="mx-2 bg-white border rounded-md px-2">
                            {project.solar_panels ? project.solar_panels.price : 0}
                        </div>
                        <p className="text-gray-600 ml-2">$</p>
                    </div>




                </div>

            </div>

            {/* <div className="bg-blue-100 col-span-1 row-span-4 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Parameters
                </div>

            </div> */}

            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Voltaic system power needed
                </p>

                <p className="my-4 text-4xl font-bold text-gray-800">
                    {project.calculated.nbr_of_panels_required ? project.calculated.voltaique_system_power.toFixed(2) : 0} W
                </p>
            </div>
            

            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Total solar pannels needed
                </p>

                <div className="flex flex-col">
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {project.calculated.nbr_of_panels_required ? project.calculated.nbr_of_panels_required : 0} pannels
                    </p>
                </div>
            </div>
            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Solar factor
                </p>

                <p className="my-4 text-4xl font-bold text-gray-800">
                    {project.solar_factor.SW_DWN ? project.solar_factor.SW_DWN.avg.toFixed(2) : 0} kWh/m<sup>2</sup>/jour
                </p>
            </div>
            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Max number of pannels per strings
                </p>

                <div className="flex flex-col">
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {project.calculated.max_panels_string_formula ? project.calculated.max_panels_string_formula : 0} pannels
                    </p>
                </div>
            </div>
            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Number of strings
                </p>

                <div className="flex flex-col">
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {project.calculated.nbr_string_formula ? project.calculated.nbr_string_formula : 0} strings
                    </p>
                </div>
            </div>





        </div>
    )
}

export default PVPanel;