// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { useEffect } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import DashboardLeftMenu from "../../Components/Dashboard/DashboardLeftMenu/DashboardLeftMenu";
import MainDashboard from "./MainDashboard/MainDashboard";
import ProjectsDashboard from "./ProjectsDashboard/ProjectsDashboard";
import ProjectDashboard from "./ProjectEditDashboard/ProjectDashboard";

function Dashboard() {

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    useEffect(() => {
        if (window.location.pathname === "/dashboard") {
            navigate("/dashboard/projects");
        }
    }, []);


    return (
        <div className="App overflow-y-hidden ">

            <div className="relative bg-white dark:bg-gray-800">
                <div className="flex flex-col sm:flex-row  min-h-[calc(100vh-100px)] w-full">

                    <DashboardLeftMenu />

                    {
                        window.location.pathname === "/dashboard" &&
                        <MainDashboard />
                    }
                    {
                        
                        window.location.pathname === "/dashboard/projects" &&
                        (queryParameters.get("project_id") === null ?
                        <ProjectsDashboard /> :
                        <ProjectDashboard />)
                    }
                    
                </div>
            </div>







        </div>
    );
}

export default Dashboard;
