// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { useEffect } from "react";
import { NavLink } from "react-router-dom";


function getNavClasses(isActive: boolean) {
    if (isActive) {
        return "bg-gray-600 text-gray-100";
    } else {
        return "text-gray-400";
    }
}


function DashboardLeftMenu(props: any) {

    return (
        <div className="w-56 flex-none">
            <nav className="mt-10 px-6 ">
                {/* <NavLink to={"/dashboard"} end className={({ isActive, isPending }) => getNavClasses(isActive) + " flex items-center p-2 my-6 transition-colors hover:text-white hover:bg-gray-600 duration-200 rounded-lg"} aria-current="page">
                    <span className="mx-4 text-lg font-normal">
                        Dashboard
                    </span>
                    <span className="flex-grow text-right">
                    </span>
                </NavLink> */}

                <NavLink to={"/dashboard/projects"} className={({ isActive, isPending }) => getNavClasses(isActive) + " flex items-center p-2 my-6 transition-colors hover:text-white hover:bg-gray-600 duration-200 rounded-lg"} aria-current="page">
                    <span className="mx-4 text-lg font-normal">
                        Projets
                    </span>
                    <span className="flex-grow text-right">
                    </span>
                </NavLink>

                {/* <NavLink to={"/account"} className={({ isActive, isPending }) => getNavClasses(isActive) + " flex items-center p-2 my-6 transition-colors hover:text-white hover:bg-gray-600 duration-200 rounded-lg"} aria-current="page">
                    <span className="mx-4 text-lg font-normal">
                        Account Settings
                    </span>
                    <span className="flex-grow text-right">
                    </span>
                </NavLink>

                <NavLink to={"/"} className={({ isActive, isPending }) => getNavClasses(isActive) + " flex items-center p-2 my-6 transition-colors hover:text-white hover:bg-gray-600 duration-200 rounded-lg"} aria-current="page">
                    <span className="mx-4 text-lg font-normal">
                        other
                    </span>
                    <span className="flex-grow text-right">
                    </span>
                </NavLink> */}
            </nav>
        </div>
    );
}

export default DashboardLeftMenu;
