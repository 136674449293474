
import { useHandleFetchEditProject } from "../ApiCalls";
import { projectType, actionType } from "../Types";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function BasicPanel({project, dispatch} : Props) {
    const handleFetchEditProject = useHandleFetchEditProject()


    function handleChangeNbrPersons(e: React.ChangeEvent<HTMLInputElement>) {

        // TODO : BETTER VALIDATION
        const newValue = e.target.value;        
        handleFetchEditProject(project.project_id, "nbr_persons", newValue, dispatch)
        dispatch({ type: "edit_project", project_id: project.project_id, column: "nbr_persons", value: newValue })
    }

    function handleChangeProjectName(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        handleFetchEditProject(project.project_id, "project_name", newValue, dispatch)
        dispatch({ type: "edit_project", project_id: project.project_id, column: "project_name", value: newValue })
    }

    function handleChangeProjectNotes(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const newValue = e.target.value;
        handleFetchEditProject(project.project_id, "project_notes", newValue, dispatch)
        dispatch({ type: "edit_project", project_id: project.project_id, column: "project_notes", value: newValue })
    }

    function handleChangeSystemVoltage(e: React.ChangeEvent<HTMLSelectElement>) {
        const newValue = parseInt(e.target.value);
        handleFetchEditProject(project.project_id, "system_voltage", newValue, dispatch)
        dispatch({ type: "edit_project", project_id: project.project_id, column: "system_voltage", value: newValue })
    }

    return (
        <div className="grid grid-cols-2 gap-4 m-10">
            <div className="col-span-2">
                <div className="text-2xl font-semibold">
                    Nom du projet
                </div>
                <input type="text" className="border rounded-md w-full px-3 py-2" value={project.project_name} onChange={handleChangeProjectName} />
            </div>
            <div className="col-span-2">
                <div className="text-2xl font-semibold">
                    Notes du projet
                </div>
                <textarea className="border rounded-md w-full min-h-[100px] px-3 py-2" value={project.project_notes} onChange={handleChangeProjectNotes} />
            </div>
            <div className="col-span-1">
                <div className="text-2xl font-semibold">
                    Nombre de personnes
                </div>
                <input type="number" className="border rounded-md w-1/2 px-3 py-2" value={project.nbr_persons} onChange={handleChangeNbrPersons} />
            </div>
            <div className="col-span-1">
                <div className="text-2xl font-semibold">
                    Voltage du système
                </div>
                <select className={"border rounded-md w-1/2 px-3 py-2 bg-white"} onChange={handleChangeSystemVoltage} value={project.system_voltage} >
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                </select>
                {/* <input type="number" className="border rounded-md w-1/2 px-3 py-2" value={project.system_voltage} onChange={handleChangeSystemVoltage} /> */}
            </div>
        </div>
    )
}

export default BasicPanel;