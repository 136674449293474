// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { Link } from "react-router-dom";


interface Props {
    onClick: (project_id: Number) => void;
    project: any;
    // any props that come into the component
}
function SmallProjectCard({project, onClick}: Props) {

    function handleOnClick() {
        onClick(project.project_id);
    }

    return (

        <Link to={"?project_id=" + project.project_id} className="p-4 bg-white shadow-lg border-2 border-black rounded-2xl w-4/5 min-w-[250px] max-w-[400px] h-64 dark:bg-gray-400 flex hover:bg-white hover:border-gray-800 hover:border-4 select-none cursor-pointer justify-center transition ease-in duration-200" onClick={handleOnClick}>

            <p className="my-auto text-4xl font-bold text-gray-900 break-all">
                {project.project_name}
            </p>


        </Link>

    );
}

export default SmallProjectCard;
