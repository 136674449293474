
import { useContext, useEffect, useState } from "react";
import { useHandleFetchEditChargeController, useHandleFetchEditChargeControllerData, useHandleFetchEditProject } from "../ApiCalls";
import { projectType, actionType, chargeControllerType } from "../Types";
import { context } from "../../../../App";
import { backEndUrl } from "../../../../config";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function OverviewPanel({ project, dispatch }: Props) {
    const handleFetchEditChargeController = useHandleFetchEditChargeController()
    const handleFetchEditChargeControllerData = useHandleFetchEditChargeControllerData()
    const [availableChargeControllers, setAvailableChargeControllers] = useState<chargeControllerType[]>([])
    const myContext = useContext(context)!;



    useEffect(() => {
        async function fetchData() {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myContext.token}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(backEndUrl + '/chargeController', options)
            const resJSON = await response.json();
            setAvailableChargeControllers(resJSON);



        }
        fetchData()
            .catch(err => console.error(err));
    }, [])



    return (
        <div className="grid grid-cols-4 gap-4 m-10">



            {[
                { label: "System Capacity", value: project.calculated.system_capacity_ah.toFixed(2), unit: "Ah" },
                { label: "Total Consumption Daily", value: project.calculated.total_consumption_daily.toFixed(2), unit: "kWh" },
                { label: "Total battery capacity needed (Ah)", value: project.calculated.total_capacity_ah.toFixed(2), unit: "Ah" },
                { label: "Number of Batteries", value: project.calculated.number_of_batteries, unit: "batteries" },
                { label: "Voltaic System Power", value: project.calculated.voltaique_system_power?.toFixed(2), unit: "W" },
                { label: "Number of Panels Required", value: project.calculated.nbr_of_panels_required, unit: "panels" },
                { label: "Number of Strings", value: project.calculated.nbr_string_formula, unit: "strings" },
                { label: "Max Panels/String", value: project.calculated.max_panels_string_formula, unit: "panels" },
                { label: "ISCEE", value: project.calculated.iscee?.toFixed(2), unit: "A" },
                { label: "ISCJA", value: project.calculated.iscja?.toFixed(2), unit: "A" },
            ].map((item, index) => (
                <div key={index} className="p-4 bg-gray-100 border shadow-lg rounded-2xl">
                    <p className="ml-2 text-gray-700 text-lg">
                        {item.label}
                    </p>
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {item.value} {item.unit}
                    </p>
                </div>
            ))}




        </div >




    )
}

export default OverviewPanel;