// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { useContext, useEffect, useState } from "react";
import SmallProjectCard from "../../../Components/Dashboard/ProjectsDashboard/SmallProjectCard";
import { context } from "../../../App";
import CreateNewProject from "../../../Components/Dashboard/ProjectsDashboard/CreateNewProject";
import TwoButtonsPopup from "../../../Components/Popups/TwoButtonsPopup";
import { backEndUrl } from "../../../config";



function ProjectsDashboard() {
    async function handleFetchGetProjects() {
        // get all projects
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${myContext.token}`
            }
        };

        const response = await fetch(backEndUrl + '/projects', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        setProjects(resJSON);
    }

    async function handleFetchCreateNewProject() {
        // get all projects
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${myContext.token}`
            },
            body: JSON.stringify({
                project_name: "Nouveau Projet",
                project_notes: "Notes du nouveau projet"
            })
        };

        const response = await fetch(backEndUrl + '/projects', options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }

        // setProjects(resJSON);
    }

    // const navigate = useNavigate();
    const myContext = useContext(context)!;

    const [projects, setProjects] = useState<any[]>([]);

    useEffect(() => {

        handleFetchGetProjects()
            .catch(err => console.error(err));

    }, []);


    function handleClickProject(project_id: Number) {
        // console.log(project_id)
    }

    async function handlePopupCreateNewProject() {
        myContext.setCurrentPopup(null);
        await handleFetchCreateNewProject();
        await handleFetchGetProjects();
        // console.log("creating new project....")
    }

    function handlePopupClose() {
        myContext.setCurrentPopup(null);
    }

    function handleClickNewProject() {
        myContext.setCurrentPopup(
            <TwoButtonsPopup title="Créé un nouveau projet?" desc="Êtes vous sûr de créé un nouveau projet?" button1Text="Oui" button2Text="Non" clickButton1={handlePopupCreateNewProject} clickButton2={handlePopupClose} />
        )
    }
    return (


        <div className="bg-gray-200  mx-0 w-full ">

            {/* <div className="w-1/2 mx-auto mt-20 " id="projectSearchBar">

                <input type="search" id="default-search" className="block w-full p-3 pl-5 text-lg font-medium text-gray-900 border border-gray-800 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500  transition duration-200 " placeholder="Search..." required>

                </input>
            </div> */}

            <div className="grid grid-cols-1 gap-10 m-20 place-items-center lg:grid-cols-2 xl:grid-cols-3">

                <CreateNewProject onClick={handleClickNewProject} />

                {
                    projects.length > 0 &&
                    projects.map((project, index) => {
                        return (
                            <SmallProjectCard key={index} project={project} onClick={handleClickProject} />
                        )
                    })
                }
                {/* <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard />
                <SmallProjectCard /> */}
            </div>


            {/* <TwoButtonsPopup clickButton1={handleCreateNewProject} clickButton2={handleCreateNewProject} /> */}



        </div>

    );
}

export default ProjectsDashboard;
