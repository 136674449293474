import { useState } from "react";

import { backEndUrl } from "../../config";

function ForgotPassword() {

    const [email, setEmail] = useState("");

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    async function handleLoginSubmit(event: any) {
        event.preventDefault();


        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": email,
            })
        };

        const response = await fetch(`${backEndUrl}/auth/forgot-password`, options)
        if (!response.ok) {
            console.error(response.statusText);
            if (response.status === 401) {
                alert("Wrong email")
            }
            return;
        }

        const data = await response.json()
        alert(data.message)
    }





    return (
        <div className="block mx-auto w-96 min-h-full flex-col justify-center px-6 py-12 lg:px-8 shadow md:mt-10 bg-gray-100 rounded-lg border-gray-200 border">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Mot de passe oublié</h2>
            </div>

            <div className="mt-10">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Email</label>
                        <input id="email" name="email" type="email" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter email" onChange={handleEmailChange} />


                    </div>



                    <div>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={handleLoginSubmit}>Envoyer le lien de réinitialisation</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default ForgotPassword;
