import { useContext, useEffect, useReducer, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { context } from "../../../App";
import { backEndUrl } from "../../../config";
import { Libraries, useLoadScript } from "@react-google-maps/api";
import GoogleMaps from "../../../Components/Dashboard/ProjectDashboard/Location/GoogleMaps";
import BasicPanel from "./BasicPanel/BasicPanel";
import ConsumptionPanel from "./ConsumptionPanel/ConsumptionPanel";
import BatteriesPanel from "./BatteriesPanel/BatteriesPanel";
import ControllerPanel from "./ControllerPanel/ControllerPanel";
import { useHandleFetchEditProject } from "./ApiCalls";
import PVPanel from "./PVPanel/PVPanel";
import SolarFactorPanel from "./SolarFactorPanel/SolarFactorPanel";

import { projectType, actionType } from "./Types";
import OverviewPanel from "./OverviewPanel/OverviewPanel";






const defaultProject = {

    "project_id": 0,
    "project_name": "Project",
    "project_notes": "Notes",
    "created_at": new Date("2000-01-01"),
    "client_id": 0,
    "technician_id": 0,
    "nbr_persons": 0,
    "location": "montreal",
    "inverter_efficiency": 0,
    "system_voltage": 0,
    "days_autonomy": 0,
    "percent_discharge": 0,
    "controller_efficiency": 0,
    "sunshine_hours": 0,
    "pannels_watt": 0,
    "max_voltage_controller": 0,
    "voc_panels": 0,
    "coef_temp_panels": 0,
    "isc_panels": 0,
    "nbr_panels_series": 0,
    "real_voltage_circuit": 0,
    "accessories": [],
    "solar_panels": {
        "solar_panel_id": 0,
        "product_id": 0,
        "image_url": "",
        "type": "",
        "nominal_power": 0,
        "nominal_voltage": 0,
        "short_circuit_current": 0,
        "open_circuit_voltage": 0,
        "optimal_current": 0,
        "optimal_voltage": 0,
        "temperature_coefficient_pmax": 0,
        "temperature_coefficient_voc": 0,
        "max_fuse_rating": 0,
        "max_voltage": 0,
        "cable_length": 0,
        "height": 0,
        "width": 0,
        "thickness": 0,
        "weight": 0,
        "certifications": "",
        "quantity": 0,
        "documents": [],
        "supplier_id": 0,
        "category_id": 0,
        "product_name": "Solar panel",
        "description": "Desc",
        "price": 0,
        "stock_quantity": 0
    },
    "batteries": {
        "battery_id": 0,
        "product_id": 0,
        "type": "",
        "nominal_voltage": 0,
        "capacity_20h": 0,
        "capacity_100h": 0,
        "recommended_output_current": 0,
        "recommended_charging_current": 0,
        "weight": 0,
        "max_parallel": 0,
        "ambient_temp": 0,
        "quantity": 0,
        "documents": [],
        "supplier_id": 0,
        "category_id": 0,
        "product_name": "Battery",
        "description": "Desc",
        "price": 0,
        "stock_quantity": 0,
        "image_url": ""
    },
    "charge_controllers": {
        "charge_controller_id": 0,
        "battery_voltage": "12, 24, 48",
        "max_current_12v_battery": 0,
        "max_current_24v_battery": 0,
        "max_current_48v_battery": 0,
        "max_pv_array_12v": 0,
        "max_pv_array_24v": 0,
        "max_pv_array_48v": 0,
        "max_pv_open_circuit_voltage_12v": 0,
        "max_pv_open_circuit_voltage_24v": 0,
        "max_pv_open_circuit_voltage_48v": 0,
        "min_pwm_mppt_voltage_12v": 0,
        "min_pwm_mppt_voltage_24v": 0,
        "min_pwm_mppt_voltage_48v": 0,
        "number_of_mppt": 1,
        "max_current_per_mppt": 0,
        "number_of_strings_per_mppt": 1,
        "efficiency": 0,
        "supported_battery_types": "Battery types",
        "terminal_block_size_awg": 0,
        "terminal_block_temp_rating_c": 0,
        "ambient_temperature": "0",
        "grounding": "0",
        "certifications": "",
        "width_cm": 0,
        "height_cm": 0,
        "depth_cm": 0,
        "weight_kg": 0,
        "price": 0,
        "product_name": "Default charge controller",
        "product_id": 0,
        "type": "MPPT",
        "image_url": "chargeControllers/759/images/0.jpg",
        "documents": []
    },
    "solar_factor": {
        "SW_DWN": {
            "jan": 1,
            "feb": 1,
            "mar": 1,
            "apr": 1,
            "may": 1,
            "jun": 1,
            "jul": 1,
            "aug": 1,
            "sep": 1,
            "oct": 1,
            "nov": 1,
            "dec": 1,
            "avg": 1
        },
    },
    "calculated": {
        "total_ac_consumption_daily": 0,
        "total_dc_consumption_daily": 0,
        "total_consumption_daily": 0,
        "system_capacity_ah": 0,
        "total_capacity_ah": 0,
        "number_of_batteries": -1,
        "voltaique_system_power": 0,
        "nbr_of_panels_required": 0,
        "percent_variation_temp": 0,
        "ajusted_voc_formula": 0,
        "ajusted_voc_standard": 0,
        "max_panels_string_formula": 0,
        "max_panels_string_standard": 0,
        "nbr_string_formula": 0,
        "nbr_string_standard": 0,
        "iscee": 0,
        "iscja": 0
    },
}




function ProjectDashboard() {

    const handleFetchEditProject = useHandleFetchEditProject();
    // TODO REMOVE THIS AND PUT IT IN A CUSTOM HOOK
    async function handleFetchGetProject() {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${myContext.token}`,
                'Content-Type': 'application/json'
            }
        };

        const response = await fetch(backEndUrl + '/projects/' + project_id, options)
        const resJSON = await response.json();

        if (response.status === 401 || response.status === 403) {
            myContext.setToken("");
        }
        if (response.status !== 200) {
            throw new Error("Error getting project");
        }
        return resJSON;
    }


    function reducer(project: projectType, action: actionType): projectType {
        console.log("project updated! : " + action.type)
        switch (action.type) {
            case 'set': {
                return action.project;
            }
            case 'edit_project': {
                return { ...project, [action.column]: action.value };
            }
            case 'refresh_calculations': {
                return { ...project, calculated: action.calculations }
            }

            case 'edit_project_accessory': {
                // TODO : REMOVE THIS AND PUT IT IN A CUSTOM HOOK

                const newAccessories = project.accessories.map(accessory => {
                    if (accessory.project_accessories_id === action.project_accessories_id) {
                        return { ...accessory, [action.column]: action.value }
                    }
                    return accessory;
                })
                return { ...project, accessories: newAccessories };
            }
            case 'delete_accessory': {
                // handleFetchDeleteProjectAccessory(action.accessory_id)
                const newAccessories = project.accessories.filter(accessory => accessory.project_accessories_id !== action.project_accessories_id);
                return { ...project, accessories: newAccessories };
            }
            case 'add_accessory': {
                const newAccessories = [...project.accessories, action.accessory];
                return { ...project, accessories: newAccessories };
            }
            case 'edit_solar_panel': {
                return { ...project, solar_panels: action.solar_panel };
            }
            case 'edit_solar_panel_data': {
                const newPannel = { ...project.solar_panels, [action.column]: action.value };
                return { ...project, solar_panels: newPannel };
            }
            case 'edit_battery': {
                return { ...project, batteries: action.battery };
            }
            case 'edit_battery_data': {
                const newBattery = { ...project.batteries, [action.column]: action.value };
                return { ...project, batteries: newBattery };
            }
            case 'edit_charge_controller': {
                return { ...project, charge_controllers: action.charge_controller };
            }
            case 'edit_charge_controller_data': {
                const newController = { ...project.charge_controllers, [action.column]: action.value };
                return { ...project, charge_controllers: newController };
            }

            default:
                return project;
        }
    }



    const myContext = useContext(context)!;
    const [queryParameters] = useSearchParams();
    const project_id = queryParameters.get("project_id");

    const [currentPanel, setCurrentPanel] = useState<number>(0);
    const [project, dispatch] = useReducer(reducer, defaultProject);


    const [libraries] = useState<Libraries>(['places']);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCquxL20Qpr76b2kA3zPqKYZRpozUAynwI",
        libraries,
    })

    function handleChangeLocation(newLocation: string) {
        console.log("changed location to " + newLocation)
        handleFetchEditProject(project.project_id, "location", newLocation, dispatch)
        dispatch({
            type: "edit_project",
            project_id: project.project_id,
            column: "location",
            value: newLocation
        })

    }

    function handlePressNextPage() {
        if (currentPanel < 7) {
            setCurrentPanel(currentPanel + 1);

        }
    }


    useEffect(() => {


        handleFetchGetProject().then(resJSON => {
            dispatch({
                type: 'set',
                project: resJSON
            })
        }).catch(err => console.error(err));


    }, []);





    return (


        <div className="bg-white dark:bg-gray-100 mx-0 w-full max-h-full">

            <div className="flex mx-10">
                <div className="w-[36rem] my-2 p-1 bg-blue-200 border border-gray-500 shadow-lg rounded-2xl mr-10">

                    <p className="py-1 text-2xl font-bold text-gray-800">
                        Identifications
                    </p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className="flex text-left h-fit">
                            <div className="flex-none">
                                Client :
                            </div>

                            <div className=" bg-blue-100 rounded-md px-2 border border-gray-500 w-full mx-2">
                                {project.client_id}
                            </div>
                        </div>
                        <div className="flex text-left h-fit">
                            <div className="flex-none">
                                Date de création :
                            </div>
                            <div className=" bg-blue-100 rounded-md px-2 border border-gray-500 w-full mx-2">
                                {project.created_at ? project.created_at.toString().split("T")[0] : "N/A"}
                            </div>
                        </div>
                        <div className="flex text-left h-fit">
                            <div className="flex-none">
                                Projet :
                            </div>
                            <div className=" bg-blue-100 rounded-md px-2 border border-gray-500 w-full mx-2">
                                {project.project_name}
                            </div>
                        </div>
                        <div className="flex text-left h-fit">
                            <div className="flex-none">
                                Projet ID :
                            </div>
                            <div className=" bg-blue-100 rounded-md px-2 border border-gray-500 w-full mx-2">
                                {project.project_id}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="min-w-[26rem] max-w-[36rem] my-2 p-1 bg-red-200 border border-gray-500 shadow-lg rounded-2xl ml-auto">

                    <p className="py-1 text-2xl font-bold text-gray-800">
                        Résumé
                    </p>
                    <div className="grid grid-cols-2 gap-2">
                        <div className="flex text-left h-fit">
                            Panneaux PV :

                            <div className="mx-1 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.calculated.nbr_of_panels_required ? project.calculated.nbr_of_panels_required : 0}
                            </div>
                            <div className="mx-1 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.solar_panels && project.calculated.nbr_of_panels_required ? project.solar_panels.nominal_power * project.calculated.nbr_of_panels_required : 0}
                            </div>
                            <div className="text-gray-500">W</div>
                        </div>
                        <div className="flex text-left h-fit">
                            Controlleurs :
                            <div className="mx-1 ml-2 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.charge_controllers ? project.charge_controllers.number_of_mppt : 0}
                            </div>
                            <div className="mx-1 bg-red-100 rounded-md px-2 border border-gray-500">
                                {
                                    project.system_voltage === 12 && project.charge_controllers && project.charge_controllers.max_pv_array_12v
                                }
                                {
                                    project.system_voltage === 24 && project.charge_controllers && project.charge_controllers.max_pv_array_24v
                                }
                                {
                                    project.system_voltage === 48 && project.charge_controllers && project.charge_controllers.max_pv_array_48v
                                }
                            </div>
                            <div className="text-gray-500">W</div>
                        </div>
                        <div className="flex text-left h-fit">
                            Batteries :
                            <div className="mx-1 ml-2 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.calculated.number_of_batteries ? project.calculated.number_of_batteries : 0}
                            </div>
                            <div className="mx-1 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.batteries ? project.batteries.capacity_20h : 0}
                            </div>
                            <div className="text-gray-500">Ah</div>
                        </div>
                        <div className="flex text-left h-fit">
                            Onduleur :
                            <div className="mx-1 ml-2 bg-red-100 rounded-md px-2 border border-gray-500">
                                1
                            </div>
                            <div className="mx-1 bg-red-100 rounded-md px-2 border border-gray-500">
                                {project.inverter_efficiency}
                            </div>
                            <div className="text-gray-500">%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-4 m-10 bg-white h-4/5 shadow-lg">
                <div className="text-2xl flex h-20 bg-white" id="projectNavbar">
                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 0 && "bg-gray-200")} onClick={() => setCurrentPanel(0)}>
                        Information du projet
                    </button>


                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 1 && "bg-gray-200")} onClick={() => setCurrentPanel(1)}>
                        Localisation
                    </button>

                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 2 && "bg-gray-200")} onClick={() => setCurrentPanel(2)}>
                        Facteur Solaire
                    </button>


                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 3 && "bg-gray-200")} onClick={() => setCurrentPanel(3)}>
                        Consommation
                    </button>

                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 4 && "bg-gray-200")} onClick={() => setCurrentPanel(4)}>
                        Batteries
                    </button>

                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 5 && "bg-gray-200")} onClick={() => setCurrentPanel(5)}>
                        Panneaux solaires
                    </button>

                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 6 && "bg-gray-200")} onClick={() => setCurrentPanel(6)}>
                        Controlleur
                    </button>

                    <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 7 && "bg-gray-200")} onClick={() => setCurrentPanel(7)}>
                        Sommaire
                    </button>



                    {/* <button className={"w-full border border-gray-400 transition ease-in duration-100 hover:bg-gray-200 " + (currentPanel === 6 && "bg-gray-200")} onClick={() => setCurrentPanel(6)}>
                        Overview
                    </button> */}
                </div>
                <div className="m-5">
                    {currentPanel === 0 &&
                        <BasicPanel project={project} dispatch={dispatch} />
                    }
                    {
                        (isLoaded && currentPanel === 1) && <GoogleMaps location={project.location} setLocation={handleChangeLocation} />
                    }
                    {
                        currentPanel === 3 &&
                        <ConsumptionPanel project={project} dispatch={dispatch} />
                    }
                    {
                        currentPanel === 2 &&
                        <SolarFactorPanel project={project} dispatch={dispatch} />
                    }
                    {
                        currentPanel === 4 &&
                        <BatteriesPanel project={project} dispatch={dispatch} />
                    }

                    {
                        currentPanel === 5 &&
                        <PVPanel project={project} dispatch={dispatch} />
                    }
                    {
                        currentPanel === 6 &&
                        <ControllerPanel project={project} dispatch={dispatch} />
                    }
                    {
                        currentPanel === 7 &&
                        <OverviewPanel project={project} dispatch={dispatch} />
                    }

                    <div className="absolute right-5 bottom-5">
                        <Link to={"/dashboard/projects"} className="border rounded-md mx-2 px-3 py-2">
                            Annuler
                        </Link>
                        <button className={"border rounded-md mx-2 px-3 py-2 bg-gray-600 text-white"} onClick={handlePressNextPage}>
                            Next
                        </button>
                        <Link to={"/dashboard/projects"} className="border rounded-md mx-2 px-3 py-2 bg-gray-700 text-white">
                            Sauvegarder
                        </Link>
                    </div>

                </div>
            </div>



        </div>

    );
}

export default ProjectDashboard;
