
import { useContext, useEffect, useState } from "react";
import { useHandleFetchEditChargeController, useHandleFetchEditChargeControllerData, useHandleFetchEditProject } from "../ApiCalls";
import { projectType, actionType, chargeControllerType } from "../Types";
import { context } from "../../../../App";
import { backEndUrl } from "../../../../config";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function ControllerPanel({ project, dispatch }: Props) {
    const handleFetchEditProject = useHandleFetchEditProject()
    const handleFetchEditChargeController = useHandleFetchEditChargeController()
    const handleFetchEditChargeControllerData = useHandleFetchEditChargeControllerData()
    const [availableChargeControllers, setAvailableChargeControllers] = useState<chargeControllerType[]>([])
    const myContext = useContext(context)!;



    useEffect(() => {
        async function fetchData() {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myContext.token}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(backEndUrl + '/chargeController', options)
            const resJSON = await response.json();
            setAvailableChargeControllers(resJSON);



        }
        fetchData()
            .catch(err => console.error(err));
    }, [])

    function handleChangeChargeController(e: React.ChangeEvent<HTMLSelectElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        const newController = availableChargeControllers.find(chargeController => chargeController.charge_controller_id === parseInt(newValue));
        // update state of current value
        if (newController) {
            // dispatch({ type: "edit_battery", battery: newBattery })
            handleFetchEditChargeController(project.project_id, newController.charge_controller_id, dispatch)
        }
    }

    // handle change panel data
    function handleChangeData(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        const column = e.target.name;

        if (!/^[0-9.-]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditChargeControllerData(project.project_id, project.charge_controllers.charge_controller_id, column, newValue, dispatch)
        dispatch({ type: "edit_charge_controller_data", column: column, value: newValue });
    }

    function handleChangeSystemTension(e: React.ChangeEvent<HTMLSelectElement>) {

        // TODO : BETTER VALIDATION
        let newValue = parseInt(e.target.value);
        // update state of current value
        dispatch({ type: "edit_project", project_id: project.project_id, column: "system_voltage", value: newValue })
        handleFetchEditProject(project.project_id, "system_voltage", newValue, dispatch)
    }

    return (
        <div className="grid grid-cols-4 gap-4 m-10">
            <select className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-96 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="solar Panels" onChange={handleChangeChargeController}>
                <option value={project.charge_controllers ? project.charge_controllers.charge_controller_id : ""} disabled>Select Charge Controller</option>
                {availableChargeControllers.map((chargeController, index) => {
                    return (
                        <option key={index} value={chargeController.charge_controller_id}>
                            {chargeController.product_name}
                        </option>
                    )
                })}

            </select>
            <div className="col-span-2"></div>
            <div className="bg-yellow-100 col-span-2 row-span-6 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Charge controller
                </div>
                <div className="text-center text-2xl font-bold">
                    {project.charge_controllers ? project.charge_controllers.product_name : "default name"}
                </div>

                {
                    project.system_voltage === 12 &&

                    <div className="grid grid-cols-2 gap-2">
                        <img className="row-span-6 object-contain max-h-[300px]" src={backEndUrl + "/" + project.charge_controllers?.image_url} />
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Type :
                            <div className="mx-2 bg-gray-100 border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.type : 0}
                            </div>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Max. Wattage/Controller 12V :
                            <input name="max_pv_array_12v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_array_12v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">W</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Efficiency :
                            <input name="efficiency" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.efficiency : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">%</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max current output 12V :
                            <input name="max_current_12v_battery" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.max_current_12v_battery : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Minimum pwm mppt voltage 12V :
                            <input name="min_pwm_mppt_voltage_12v" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.min_pwm_mppt_voltage_12v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max PV open circuit voltage 12V :
                            <input name="max_pv_open_circuit_voltage_12v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_open_circuit_voltage_12v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Weight :
                            <input name="weight_kg" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.weight_kg : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">kg</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Number of mppt :
                            <input name="number_of_mppt" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.number_of_mppt : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2"></p>
                        </div>


                        <div className="mx-2 px-2 py-1 row-span-3">
                            Files
                            <div>
                                {project.charge_controllers && project.charge_controllers.documents ? project.charge_controllers.documents.map((file, index) => {
                                    return (
                                        <div key={index} className="mx-2 bg-white border rounded-md px-2">
                                            <a href={backEndUrl + "/" + file.document_url} target="_blank" rel="noreferrer">{file.document_name}</a>
                                        </div>
                                    )
                                }
                                ) : ""}


                            </div>

                        </div>

                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Available voltages :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.battery_voltage : 0}
                            </div>
                            <p className="text-gray-600 ml-2">V</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Price :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.price : 0}
                            </div>
                            <p className="text-gray-600 ml-2">$</p>
                        </div>
                    </div>

                }

                {
                    project.system_voltage === 24 &&

                    <div className="grid grid-cols-2 gap-2">
                        <img className="row-span-6 object-contain max-h-[300px]" src={backEndUrl + "/" + project.charge_controllers?.image_url} />
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Type :
                            <div className="mx-2 bg-gray-100 border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.type : 0}
                            </div>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Max. Wattage/Controller 24V :
                            <input name="max_pv_array_24v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_array_24v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">W</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Efficiency :
                            <input name="efficiency" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.efficiency : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">%</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max current output 24V :
                            <input name="max_current_24v_battery" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.max_current_24v_battery : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Minimum pwm mppt voltage 24V :
                            <input name="min_pwm_mppt_voltage_24v" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.min_pwm_mppt_voltage_24v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max PV open circuit voltage 24V :
                            <input name="max_pv_open_circuit_voltage_24v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_open_circuit_voltage_24v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Weight :
                            <input name="weight_kg" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.weight_kg : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">kg</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Number of mppt :
                            <input name="number_of_mppt" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.number_of_mppt : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2"></p>
                        </div>


                        <div className="mx-2 px-2 py-1 row-span-3">
                            Files
                            <div>
                                {project.charge_controllers && project.charge_controllers.documents ? project.charge_controllers.documents.map((file, index) => {
                                    return (
                                        <div key={index} className="mx-2 bg-white border rounded-md px-2">
                                            <a href={backEndUrl + "/" + file.document_url} target="_blank" rel="noreferrer">{file.document_name}</a>
                                        </div>
                                    )
                                }
                                ) : ""}


                            </div>

                        </div>

                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Available voltages :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.battery_voltage : 0}
                            </div>
                            <p className="text-gray-600 ml-2">V</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Price :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.price : 0}
                            </div>
                            <p className="text-gray-600 ml-2">$</p>
                        </div>
                    </div>

                }

                {
                    project.system_voltage === 48 &&

                    <div className="grid grid-cols-2 gap-2">
                        <img className="row-span-6 object-contain max-h-[300px]" src={backEndUrl + "/" + project.charge_controllers?.image_url} />
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Type :
                            <div className="mx-2 bg-gray-100 border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.type : 0}
                            </div>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Max. Wattage/Controller 48V :
                            <input name="max_pv_array_48v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_array_48v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">W</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                            Efficiency :
                            <input name="efficiency" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.efficiency : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">%</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max current output 48V :
                            <input name="max_current_48v_battery" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.max_current_48v_battery : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Minimum pwm mppt voltage 48V :
                            <input name="min_pwm_mppt_voltage_48v" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.min_pwm_mppt_voltage_48v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Max PV open circuit voltage 48V :
                            <input name="max_pv_open_circuit_voltage_48v" type="number" className="bg-white border rounded-md px-2 max-w-[60px]" value={project.charge_controllers ? project.charge_controllers.max_pv_open_circuit_voltage_48v : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">A</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Weight :
                            <input name="weight_kg" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.weight_kg : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2">kg</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Number of mppt :
                            <input name="number_of_mppt" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.charge_controllers ? project.charge_controllers.number_of_mppt : 0} onChange={handleChangeData} />
                            <p className="text-gray-600 ml-2"></p>
                        </div>


                        <div className="mx-2 px-2 py-1 row-span-3">
                            Files
                            <div>
                                {project.charge_controllers && project.charge_controllers.documents ? project.charge_controllers.documents.map((file, index) => {
                                    return (
                                        <div key={index} className="mx-2 bg-white border rounded-md px-2">
                                            <a href={backEndUrl + "/" + file.document_url} target="_blank" rel="noreferrer">{file.document_name}</a>
                                        </div>
                                    )
                                }
                                ) : ""}


                            </div>

                        </div>

                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Available voltages :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.battery_voltage : 0}
                            </div>
                            <p className="text-gray-600 ml-2">V</p>
                        </div>
                        <div className="flex mx-2 px-2 py-1 ml-auto">
                            Price :
                            <div className="mx-2 bg-white border rounded-md px-2">
                                {project.charge_controllers ? project.charge_controllers.price : 0}
                            </div>
                            <p className="text-gray-600 ml-2">$</p>
                        </div>
                    </div>

                }







            </div>
            <div className="bg-blue-100 col-span-1 row-span-6 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Parameters
                </div>
                
                <div className="my-2">
                    <div className="text-xl font-semibold text-center">
                        System voltage
                    </div>

                    <div className="flex relative w-1/2 py-2 mx-auto">

                        <select className={"border rounded-md px-3 py-2 bg-white w-full"} onChange={handleChangeSystemTension} value={project.system_voltage} >
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="48">48</option>
                        </select>
                        <span className="rounded-r-md inline-flex  items-center px-3 bg-white border-t  border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            V
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Total battery capacity needed (Ah)
                </p>

                <p className="my-4 text-4xl font-bold text-gray-800">
                    {project.calculated.total_capacity_ah ? project.calculated.total_capacity_ah.toFixed(2) : 0} Ah
                </p>
            </div>




            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Total batteries needed
                </p>

                <div className="flex flex-col">
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {project.calculated.number_of_batteries ? project.calculated.number_of_batteries : 0} batteries
                    </p>
                </div>
            </div>





        </div >






    )
}

export default ControllerPanel;