import { useContext, useEffect, useState } from "react"
import useOnclickOutside from "react-cool-onclickoutside"
import { backEndUrl } from "../../../../config";
import { context } from "../../../../App";
import { accessoryType, actionType, projectType } from "../Types";
import { useHandleFetchAddProjectAccessory } from "../ApiCalls";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}

function AddNewAccessory({ project, dispatch }: Props) {
    const handleFetchAddProjectAccessory = useHandleFetchAddProjectAccessory();
    

    const myContext = useContext(context)!;
    const [isOpen, setIsOpen] = useState(false)
    const [accessories, setAccessories] = useState<any[]>([])

    const menuDiv = useOnclickOutside(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        async function fetchData() {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myContext.token}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(backEndUrl + '/accessories', options)
            const resJSON = await response.json();
            setAccessories(resJSON);
            console.log(resJSON)



        }
        fetchData()
            .catch(err => console.error(err));
    }, [])

    async function handleClickAccessory(accessory: accessoryType) {
        // set default values
        accessory.quantity = 1;
        accessory.hours_day = 24;
        accessory.days_week = 7;

        try {
            await handleFetchAddProjectAccessory(project.project_id, accessory, dispatch);

            // dispatch({ type: "add_accessory", accessory: accessory })

        } catch (err) {
            console.error(err);
        }
        setIsOpen(false);



    }

    return (

        <div className="relative w-28 mr-2 text-left mx-8" ref={menuDiv}>
            <div >
                <button type="button" className=" border border-gray-300 bg-white dark:bg-gray-800 shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500" id="options-menu" onClick={() => setIsOpen(!isOpen)} >
                    Ajouter un accessoire
                    <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z">
                        </path>
                    </svg>
                </button>
            </div>
            <div className={"absolute left-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 " + (!isOpen && "hidden")}>
                <div className="py-1 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {
                        accessories.map((accessory, index) => {
                            // filter out accessories that are already in the project
                            // const alreadyInProject = project.accessories.find((projectAccessory) => projectAccessory.accessories_id === accessory.accessories_id)
                            // if (alreadyInProject) return null;

                            return (
                                <button key={index} className="block px-4 py-2 text-md w-full text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600" role="menuitem" onClick={() => handleClickAccessory(accessory)}>
                                    <span className="flex flex-col">
                                        {accessory.accessories_name} ({accessory.watt}W {accessory.acdc})
                                    </span>
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </div>

    )
}

export default AddNewAccessory