// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";

import { Marker, GoogleMap } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import usePlacesAutocomplete, { LatLng, getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";


interface Props {
    location: string;
    setLocation: (location: string) => void;
}

function GoogleMaps({ location, setLocation }: Props) {

    const [coords, setCoords] = useState<LatLng>({ lat: 52.775, lng: -122.434 });
    const [inputLocation, setInputLocation] = useState<string>(location);
    const [focused, setFocused] = useState(false)

    const comboBoxDiv = useOnclickOutside(() => {
        setFocused(false);
    });

    function handleSelectSuggestion(suggestion_description: string) {
        setLocation(suggestion_description);
        setInputLocation(suggestion_description);
        setFocused(false);
        async function getCode() {
            const results = await getGeocode({ address: suggestion_description }).catch(err => console.error(err));
            if (results === undefined) return;
            const { lat, lng } = await getLatLng(results[0]);
            setCoords({ lat, lng });

        }
        getCode();
    };

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "get_suggestions",
        requestOptions: {
            // types: ["all"],
            
            /* Define search scope here */
        },
        debounce: 500,
    });

    useEffect(() => {
        setInputLocation(location);
        async function getCode() {
            const results = await getGeocode({ address: location }).catch(err => console.error(err));
            if (results === undefined) return;
            const { lat, lng } = await getLatLng(results[0]);
            setCoords({ lat, lng });

        }
        getCode();
    }, [location])

    useEffect(() => {
        if (inputLocation === "") return;

        // setInputLocation(location);
        setValue(inputLocation);
        clearSuggestions();
    }, [inputLocation])



    return (
        <>
            <div className="m-1 mx-20" ref={comboBoxDiv} >
                <input
                    type="text"
                    value={inputLocation}
                    onChange={(e) => setInputLocation(e.target.value)}
                    onClick={() => setFocused(!focused)}

                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 shadow-md w-full"
                />
                {focused && (
                    <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg right-[6.25rem] left-[6.25rem]">

                        {data.map((suggestion, index) => {
                            const {
                                place_id,
                                structured_formatting: { main_text, secondary_text },
                            } = suggestion;
                            return (

                                <div
                                    key={index}
                                    onClick={() => handleSelectSuggestion(suggestion.description)}
                                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                >
                                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>

            <GoogleMap
                mapContainerClassName="map-container min-h-[400px] mx-20 mt-5 border-2 border-gray-400 rounded-md shadow"
                center={coords}
                zoom={10}
                options={{ streetViewControl: false, fullscreenControl: false, mapTypeControl: false }}
            >
                {coords && <Marker position={coords} />}

            </GoogleMap>
        </>


    );
}

const PlacesAutocomplete = ({ setSelected }: any) => {
    return <></>
}

export default GoogleMaps;
