
import { useContext, useEffect, useState } from "react";
import { useHandleFetchEditBattery, useHandleFetchEditBatteryData, useHandleFetchEditProject } from "../ApiCalls";
import { actionType, batteryType, projectType } from "../Types";
import { context } from "../../../../App";
import { backEndUrl } from "../../../../config";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}


function BatteriesPanel({ project, dispatch }: Props) {
    const handleFetchEditProject = useHandleFetchEditProject()
    const handleFetchEditBattery = useHandleFetchEditBattery()
    const handleFetchEditBatteryData = useHandleFetchEditBatteryData()
    const [availableBatteries, setAvailableBatteries] = useState<batteryType[]>([])
    const myContext = useContext(context)!;


    function handleChangeBattery(e: React.ChangeEvent<HTMLSelectElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        const newBattery = availableBatteries.find(battery => battery.battery_id === parseInt(newValue));
        // update state of current value
        if (newBattery) {
            // dispatch({ type: "edit_battery", battery: newBattery })
            handleFetchEditBattery(project.project_id, newBattery.battery_id, dispatch)
        }
    }

    useEffect(() => {
        async function fetchData() {
            const options = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${myContext.token}`,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(backEndUrl + '/battery', options)
            const resJSON = await response.json();
            setAvailableBatteries(resJSON);



        }
        fetchData()
            .catch(err => console.error(err));
    }, [])

    function handleChangeInverterEfficiency(e: React.ChangeEvent<HTMLInputElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        // update state of current value
        dispatch({ type: "edit_project", project_id: project.project_id, column: "inverter_efficiency", value: newValue })
        handleFetchEditProject(project.project_id, "inverter_efficiency", newValue, dispatch)
    }

    function handleChangeSystemTension(e: React.ChangeEvent<HTMLSelectElement>) {

        // TODO : BETTER VALIDATION
        let newValue = parseInt(e.target.value);
        // update state of current value
        dispatch({ type: "edit_project", project_id: project.project_id, column: "system_voltage", value: newValue })
        handleFetchEditProject(project.project_id, "system_voltage", newValue, dispatch)
    }

    function handleChangeDaysAutonomy(e: React.ChangeEvent<HTMLInputElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        // update state of current value
        dispatch({ type: "edit_project", project_id: project.project_id, column: "days_autonomy", value: newValue })
        handleFetchEditProject(project.project_id, "days_autonomy", newValue, dispatch)
    }

    function handleChangeDischarge(e: React.ChangeEvent<HTMLInputElement>) {

        // TODO : BETTER VALIDATION
        let newValue = e.target.value;
        // update state of current value
        dispatch({ type: "edit_project", project_id: project.project_id, column: "percent_discharge", value: newValue })
        handleFetchEditProject(project.project_id, "percent_discharge", newValue, dispatch)
    }

    // handle change panel data
    function handleChangeData(e: React.ChangeEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        const column = e.target.name;

        if (!/^[0-9.-]+$/.test(newValue) && newValue !== "") return; // cancel anything thats not a number

        handleFetchEditBatteryData(project.project_id, project.batteries.battery_id, column, newValue, dispatch)
        dispatch({ type: "edit_battery_data", column: column, value: newValue });
    }


    return (
        <div className="grid grid-cols-4 gap-4 m-10">
            <select className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-96 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="solar Panels" onChange={handleChangeBattery}>
                <option value={project.batteries ? project.batteries.battery_id : ""} disabled>Select Battery</option>
                {availableBatteries.map((battery, index) => {
                    return (
                        <option key={index} value={battery.battery_id}>
                            {battery.product_name}
                        </option>
                    )
                })}

            </select>
            <div className="col-span-2"></div>
            <div className="bg-yellow-100 col-span-2 row-span-6 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Battery
                </div>
                <div className="text-center text-2xl font-bold">
                    {project.batteries ? project.batteries.product_name : "test"}
                </div>

                <div className="grid grid-cols-2 gap-2">
                    <img className="row-span-6 object-contain max-h-[300px]" src={backEndUrl + "/" + project.batteries?.image_url} />
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Type :
                        <div className="mx-2 bg-gray-100 border rounded-md px-2">
                            {project.batteries ? project.batteries.type : 0}
                        </div>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Nominal Voltage :
                        <input name="nominal_voltage" type="number" className="bg-white border rounded-md px-2 max-w-[40px]" value={project.batteries ? project.batteries.nominal_voltage : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">V</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto font-bold">
                        Capacity 20H :
                        <input name="capacity_20h" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.capacity_20h : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">Ah</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Capacity 100H :
                        <input name="capacity_100h" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.capacity_100h : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">Ah</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Recommended Output Current :
                        <input name="recommended_output_current" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.recommended_output_current : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">A</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Recommended Charge Current :
                        <input name="recommended_charging_current" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.recommended_charging_current : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">A</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Weight :
                        <input name="weight" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.weight : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2">kg</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Max. in parallel :
                        <input name="max_parallel" type="number" className="bg-white border rounded-md px-2 max-w-[50px]" value={project.batteries ? project.batteries.max_parallel : 0} onChange={handleChangeData} />
                        <p className="text-gray-600 ml-2"></p>
                    </div>


                    <div className="mx-2 px-2 py-1 row-span-3">
                        Files
                        <div>
                            {project.batteries && project.batteries.documents ? project.batteries.documents.map((file, index) => {
                                return (
                                    <div key={index} className="mx-2 bg-white border rounded-md px-2">
                                        <a href={backEndUrl + "/" + file.document_url} target="_blank" rel="noreferrer">{file.document_name}</a>
                                    </div>
                                )
                            }
                            ) : ""}


                        </div>

                    </div>

                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Ambient Temperature :
                        <div className="mx-2 bg-white border rounded-md px-2">
                            {project.batteries ? project.batteries.ambient_temp : 0}
                        </div>
                        <p className="text-gray-600 ml-2">°C</p>
                    </div>
                    <div className="flex mx-2 px-2 py-1 ml-auto">
                        Price :
                        <div className="mx-2 bg-white border rounded-md px-2">
                            {project.batteries ? project.batteries.price : 0}
                        </div>
                        <p className="text-gray-600 ml-2">$</p>
                    </div>
                </div>

            </div>

            <div className="bg-blue-100 col-span-1 row-span-6 border shadow-lg rounded-lg min-h-1/2 mb-5">
                <div className="text-center text-2xl font-bold">
                    Parameters
                </div>
                <div className="my-2">
                    <div className="text-xl font-semibold text-center">
                        Inverter efficiency
                    </div>

                    <div className="flex relative w-1/2 py-2 mx-auto">

                        <input type="number" id="with-email" className="z-10 rounded-l-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm" placeholder="90" value={project.inverter_efficiency} onChange={handleChangeInverterEfficiency} />
                        <span className="rounded-r-md inline-flex  items-center px-3 bg-white border-t  border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            %
                        </span>
                    </div>
                </div>
                <div className="my-2">
                    <div className="text-xl font-semibold text-center">
                        Percentage of discharge
                    </div>

                    <div className="flex relative w-1/2  py-2 mx-auto">

                        <input type="number" id="with-email" className="z-10 rounded-l-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm" placeholder="50" value={project.percent_discharge} onChange={handleChangeDischarge} />
                        <span className="rounded-r-md inline-flex  items-center px-3 bg-white border-t  border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            %
                        </span>
                    </div>
                </div>
                <div className="my-2">
                    <div className="text-xl font-semibold text-center">
                        Number of days of autonomy
                    </div>

                    <div className="flex relative w-1/2 py-2 mx-auto">

                        <input type="number" className="z-10 rounded-l-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm" placeholder="1" value={project.days_autonomy} onChange={handleChangeDaysAutonomy} />
                        <span className="rounded-r-md inline-flex  items-center px-3 bg-white border-t  border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            Days
                        </span>
                    </div>
                </div>
                <div className="my-2">
                    <div className="text-xl font-semibold text-center">
                        System voltage
                    </div>

                    <div className="flex relative w-1/2 py-2 mx-auto">

                        <select className={"border rounded-md px-3 py-2 bg-white w-full"} onChange={handleChangeSystemTension} value={project.system_voltage} >
                            <option value="12">12</option>
                            <option value="24">24</option>
                            <option value="48">48</option>
                        </select>
                        <span className="rounded-r-md inline-flex  items-center px-3 bg-white border-t  border-r border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            V
                        </span>
                    </div>
                </div>
            </div>





            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Total battery capacity needed (Ah)
                </p>

                <p className="my-4 text-4xl font-bold text-gray-800">
                    {project.calculated.total_capacity_ah ? project.calculated.total_capacity_ah.toFixed(2) : 0} Ah
                </p>
            </div>




            <div className="col-span-1 row-span-1 p-4 bg-gray-100 border shadow-lg rounded-2xl">
                <p className="ml-2 text-gray-700 text-lg">
                    Total batteries needed
                </p>

                <div className="flex flex-col">
                    <p className="my-4 text-4xl font-bold text-gray-800">
                        {project.calculated.number_of_batteries ? project.calculated.number_of_batteries : 0} batteries
                    </p>
                </div>
            </div>





        </div >
    )
}

export default BatteriesPanel;