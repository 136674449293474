import AccessoryRow from "./AccessoryRow";

import { projectType, actionType } from "../Types";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}

function TableConsumption({project, dispatch} : Props) {
    return (
        <div className="container max-w-4/5 px-4 mx-auto sm:px-8">
            <div className="pb-8">
                <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                    <div className="inline-block min-w-full overflow rounded-lg shadow">
                        <table className="min-w-full leading-normal border table-fixed">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Accessoire
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Description
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Ampérage (A)
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Voltage (V)
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-nboldormal text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Wattage (W)
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Type
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Quantité
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold  text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Heures par jours
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-bold  text-gray-800 uppercase bg-white border-b border-gray-200">
                                        Jours par semaine
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-normal text-gray-800 uppercase bg-white border-b border-gray-200">
                                    </th>
                                    <th scope="col" className="px-5 py-3 text-sm font-normal text-gray-800 uppercase bg-white border-b border-gray-200">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    project.accessories.map((accessory, index) => {
                                        return (
                                            <AccessoryRow key={index} accessory={accessory} dispatch={dispatch} project={project}/>
                                        )
                                    })
                                }
                                {/* <AccessoryRow accessory={project} dispatch={dispatch}/> */}
                            </tbody>
                        </table>
                        {/* <div className="flex flex-col items-center px-5 py-5 bg-white xs:flex-row xs:justify-between">
                        <div className="flex items-center">
                            <button type="button" className="w-full p-4 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-indigo-500 bg-white border-t border-b hover:bg-gray-100 ">
                                1
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                2
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100">
                                3
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                4
                            </button>
                            <button type="button" className="w-full p-4 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TableConsumption;