import TableConsumption from "./TableConsumption";
import { projectType, actionType } from "../Types";
import AddNewAccessory from "./AddNewAccessory";

type Props = {
    project: projectType,
    dispatch: React.Dispatch<actionType>
}

function ConsumptionPanel({ project, dispatch }: Props) {

    return (
        <>
            <div className="flex justify-between">
                <AddNewAccessory project={project} dispatch={dispatch}/>
                <div className="flex px-3 py-1 bg-gray-100 border border-gray-400 shadow-lg rounded-lg mx-8">
                    <p className="my-auto mx-2 text-gray-700 text-lg">
                        Consommation totale quotidienne : 
                    </p>
                    <p className="my-auto text-lg font-bold text-gray-800">
                        {project.calculated.total_consumption_daily ? project.calculated.total_consumption_daily.toFixed(2) : 0} Wh
                    </p>
                </div>
            </div>


            <TableConsumption project={project} dispatch={dispatch} />
        </>
    )
}

export default ConsumptionPanel