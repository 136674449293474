import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { context } from "../../App";
import { backEndUrl } from "../../config";

function Login() {
    const navigate = useNavigate();
    const myContext = useContext(context)!;

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event: any) {
        setPassword(event.target.value);
    }

    async function handleLoginSubmit(event: any) {
        event.preventDefault();


        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": email,
                "password": password
            })
        };

        const response = await fetch(`${backEndUrl}/auth/login`, options)
        if (!response.ok) {
            console.error(response.statusText);
            if (response.status === 401) {
                alert("Wrong email or password")
            }
            return;
        }

        const data = await response.json()
        myContext.setToken(data.token);

        navigate("/dashboard/projects");
    }





    return (
        <div className="block mx-auto w-96 min-h-full flex-col justify-center px-6 py-12 lg:px-8 shadow md:mt-10 bg-gray-100 rounded-lg border-gray-200 border">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Login</h2>
            </div>

            <div className="mt-10">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Email</label>
                        <input id="email" name="email" type="email" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter email" onChange={handleEmailChange} />


                    </div>

                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Password</label>
                        <input id="password" name="password" type="password" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter Password" onChange={handlePasswordChange} />


                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                        </div>
                        <div className="text-sm">
                            <Link to={"/forgot-password/"} className="font-semibold text-green-600 hover:text-green-500">Forgot password?</Link>
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={handleLoginSubmit}>Sign in</button>
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member?
                    <Link to={"/signup"} className="font-semibold leading-6 text-green-600 hover:text-green-500"> Sign up</Link>
                </p>
            </div>
        </div>
    );
}

export default Login;
