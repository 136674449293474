import React, { useContext } from "react";
import { useParams, Link, NavLink } from "react-router-dom";
import logoImg from "../../assets/logo/nergietechWhiteEng.png"
import { jwtDecode } from "jwt-decode";
import { context } from "../../App";

type JwtPayloadType = {
    first_name: String,
    email: String,
    exp: Number,
    iat: Number,
    isAdmin: Boolean,
    user_id: Number
}

function navLinkClasses(isActive: boolean) {
    const defaultClasses = "block py-2 pl-3 pr-4 md:p-0 text-white rounded "

    const activeClasses = "md:text-primarylink bg-primarylink rounded md:bg-transparent"
    const inactiveClasses = "hover:bg-primarybg md:hover:bg-transparent md:border-0 hover:text-primarylink"

    return isActive ? defaultClasses + activeClasses : defaultClasses + inactiveClasses;
}

function NavbarComp(props: any) {

    const myContext = useContext(context)!;

    function handleDisconnect() {
        myContext.setToken("");
    }





    return (

        <nav className="bg-primarybg border-gray-200">
            <div className="mx-5 flex flex-wrap items-center justify-between p-2 h-[100px]">
                <a href="https://nergietech.com/" className="flex items-center">
                    <img src={logoImg} className="h-24" alt="Nergietech Logo" />
                </a>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-primarybg focus:outline-none focus:ring-2 focus:ring-black" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>

                <div className="w-full md:block md:w-auto" id="navbar-default"> {/* hidden classname*/}

                    <ul className="items-center font-bold flex flex-col p-4 md:p-0 mt-4 mr-10 border border-gray-100 rounded-lg bg-primarybg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-primarybg">
                        <li>
                            <NavLink to={"/"} className={({ isActive, isPending }) => navLinkClasses(isActive)} aria-current="page">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/upload"} className={({ isActive, isPending }) => navLinkClasses(isActive)} aria-current="page">Upload</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={"/project"} className={({ isActive, isPending }) => navLinkClasses(isActive)} aria-current="page">pro</NavLink>
                        </li> */}


                        {(myContext.token === "" || myContext.token === undefined) && (
                            <>
                                <li>
                                    <Link to={"/login"} className="block text-white bg-gray-500 hover:bg-gray-600 rounded-lg px-5 py-2 my-2 text-center">Login</Link>
                                </li>
                                <li>
                                    <Link to={"/signup"} className="block text-white bg-primarylink hover:bg-green-700 rounded-lg px-5 py-2 my-2 text-center">Register</Link>
                                </li>
                            </>

                        )}


                        {(myContext.token !== "" && myContext.token !== undefined) && (
                            <>

                                <li>
                                    <p className="block py-2 pl-3 pr-4 text-white rounded md:border-0 md:p-0 text-center">Bonjour, {jwtDecode<JwtPayloadType>(myContext.token).first_name}</p>
                                    <Link to={"/dashboard/projects"} className="block text-white bg-gray-500 hover:bg-gray-600 rounded-lg px-5 py-2 m-2 text-center">Dashboard</Link>
                                </li>
                                <li>
                                    <button onClick={handleDisconnect} className="block text-black bg-red-700 hover:bg-red-800 rounded-md px-2 py-1 mx-auto">Disconnect</button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>

    )
}

export default NavbarComp
