import { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { context } from "../../App";
import { backEndUrl } from "../../config";

function ResetPassword() {
    const navigate = useNavigate();
    const [urlParams, setUrlParams] = useSearchParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    function handlePasswordChange(event: any) {
        setPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event: any) {
        setConfirmPassword(event.target.value);
    }

    async function handleResetSubmit(event: any) {
        event.preventDefault();

        // if both passwords are not the same
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "email": urlParams.get("email"),
                "password": password,
                "token": urlParams.get("token")
            })
        };

        const response = await fetch(`${backEndUrl}/auth/reset-password`, options)
        if (!response.ok) {
            console.error(response.statusText);
            if (response.status === 401) {
                alert("Wrong email")
            }
            return;
        }

        const data = await response.json()
        alert(data.message)
        navigate("/login");
    }

    useEffect(() => {
        if (urlParams.get("email") === null || urlParams.get("token") === null) {
            navigate("/login");
        }

    });




    return (
        <div className="block mx-auto w-96 min-h-full flex-col justify-center px-6 py-12 lg:px-8 shadow md:mt-10 bg-gray-100 rounded-lg border-gray-200 border">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Réinitialisation de mot de passe</h2>
            </div>

            <div className="mt-10">
                <form className="space-y-6" action="#" method="POST">
                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">New Password</label>
                        <input id="password" name="password" type="password" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Enter Password" onChange={handlePasswordChange} />


                    </div>

                    <div>
                        <label className="block text-lg font-semibold leading-6 text-gray-900">Confirm Password</label>
                        <input id="confirmpassword" name="confirmpassword" type="password" required className="font-normal pl-2 block w-full mt-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 text-md" placeholder="Confirm Password" onChange={handleConfirmPasswordChange} />


                    </div>



                    <div>
                        <button type="submit" className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600" onClick={handleResetSubmit}>Réinitialiser le mot de passe</button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default ResetPassword;
